(function() {
  'use strict';

  // Selectors
  // ---------
  window.$WINDOW = $(window);
  window.$DOCUMENT = $(document);
  window.$HTML = $(document.documentElement);
  window.$BODY = $(document.body);
  window.$HEADER = $('.header');
  window.$GO_TOP = $('#go-top-button');

  // Helpers
  // -------
  $WINDOW
    .on('calcSizes', () => {
      window.VIEWPORT_WIDTH = document.documentElement.clientWidth || Math.round(window.visualViewport.width) || $WINDOW.width();
      window.WINDOW_HEIGHT = $WINDOW.height();
      window.HEADER_HEIGHT = $HEADER.height();
    })
    .on('resize', () => {
      $WINDOW.trigger('calcSizes');
    })
    .trigger('calcSizes');


  // Defaults
  // --------
  let slickSettings = {
        autoplay: true,
        autoplaySpeed: 5000,
        prevArrow: '<div class="slick-prev"></div>',
        nextArrow: '<div class="slick-next"></div>',
        customPaging: function() { return $('<div />'); },
        draggable: false
      };

  // Masked input
  // ------------
  Inputmask.extendAliases({
    'phone-mask': {
      regex: '(\\+7|8) \\([0-689]\\d{2}\\) \\d{3}(\\-\\d{2}){2}',
      placeholder: '+7 (___) ___-__-__',
      keepStatic: true
    }
  });

  // Phone
  $('input[type="tel"], input[autocomplete="tel"]').inputmask('phone-mask');


  $WINDOW
    .one('load', () => {
      $WINDOW.trigger('calcSizes');
    })
    .on('scroll.goToButton', (e, cssClass) => {
      let scrollTop = $WINDOW.scrollTop();

      if (!cssClass)
        cssClass = 'top-button-visible';

      if (scrollTop >= HEADER_HEIGHT)
        $GO_TOP.addClass(cssClass);
      else
        $GO_TOP.removeClass();
    })
    .trigger('scroll.goToButton', ['top-button-custom-visible']);


  $DOCUMENT
    .ready(() => {
      $('#header-rotator').slick($.extend({}, slickSettings, {
        centerMode: true,
        centerPadding: '0px',
        appendArrows: '#header-rotator-controls',
        slide: '.header-rotator-slide-container'
      }));

      $('#partner-rotator').slick($.extend({}, slickSettings, {
        appendArrows: '#partner-rotator-controls',
        slide: '.partner-rotator-slide-container',
        slidesToShow: 5
      }));

      $('.responses').each((i, element) => {
        $(element).find('.items').slick($.extend({}, slickSettings, {
          appendArrows: $(element).children('.responses-controls'),
          slide: '.list-view-item',
          slidesToShow: 2
        }));
      });


    })

    // Menu events
    // -----------
    .on('click.js-menu', '.js-menu li > a, .js-menu li > span', function(e) {
      if (IS_MOBILE) {
        e.preventDefault();

        let $self = $(e.currentTarget),
            $parent = $self.closest('li'),
            isLink = $self.prop('tagName').toUpperCase() === 'A',
            isWithDropdown = $parent.hasClass('with-dropdown'),
            isOnHover = $parent.hasClass('is-hovered');

        $parent.siblings('.with-dropdown').removeClass('is-hovered').children('ul').slideUp(250);

        if (isLink) {
          if (isWithDropdown) {
            if (isOnHover)
              location.href = $self.prop('href');
            else
              $parent.addClass('is-hovered').children('ul').slideDown(250);
          }
          else
            location.href = $self.prop('href');
        }
        else {
          if (isOnHover)
            $parent.removeClass('is-hovered').children('ul').slideUp(250);
          else
            $parent.addClass('is-hovered').children('ul').slideDown(250);
        }
      }
    })

    // Go Top button
    // -------------
    .on('click.goTopButton', '#go-top-button', e => {
      e.preventDefault();

      if (!IS_MOBILE)
        $WINDOW.scrollTo(0, 200);
    })

    // Scroll to
    // ---------
    .on('click.jsScrollTo', '.js-scroll-to', function(e) {
      e.preventDefault();

      let $self = $(this),
          $element = $($self.attr('href').substring($self.attr('href').lastIndexOf('#'))),
          speed = $self.data('scrollSpeed') || 150,
          offset = $self.data('scrollOffset') || 0;

      $WINDOW.scrollTo($element, {duration: speed, offset: offset, axis: 'y'});
    })

    // Faq list
    // --------
    .on('click.faqList', '.faq-list > div', e => {
      e.preventDefault();

      $(e.currentTarget).toggleClass('active').children('div:last-child').slideToggle(250);
    });


  // FancyBox
  // --------
  $.extend(true, $.fancybox.defaults, {
    margin: 0,

    infobar: true,
    buttons: true,

    slideShow: false,
    fullScreen: false,
    thumbs: false,
    closeBtn: true,

    smallBtn: false,

    image: {
      protect: true
    },

    focus: true,
    focusElement: '.js-autofocus-inp'
  });

  $('[data-fancybox]').fancybox();

})();